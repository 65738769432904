
const industryOptions = [
    { value: "", text: "应用行业" },
    "互联网",
    "商业",
    "贸易",
    "制造业",
    "农业",
    "医疗",
    "物流",
];

const complianceOptions = [
    { value: "", text: "合规要求" },
    "GDPR",
    "CCPA",
    "PIPL",
];

const surveyTypeOptions = [
    { value: "", text: "问卷类型" },
    { value: "datamap", text: "DataMap问卷" },
    { value: "pia-ent", text: "企业PIA问卷" },
    { value: "pia-product", text: "产品PIA问卷" },
];

export { industryOptions, complianceOptions, surveyTypeOptions };