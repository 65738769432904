<template>
  <div>
    <b-card>
      <!-- search & new -->
      <b-row>
        <b-col cols="2">
          <b-form-select
            placeholder="应用行业"
            v-model="query_industry"
            :options="industryOptions"
          />
        </b-col>
        <b-col cols="2">
          <b-form-select
            placeholder="合规要求"
            v-model="query_compliance"
            :options="complianceOptions"
          />
        </b-col>
        <b-col cols="3">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="query_keyword"
              placeholder="法规内容、关键字"
              @keypress.enter="doSearch"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="cursor-pointer"
                @click="doSearch"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-col cols="5" class="text-right">
          <b-button variant="primary" @click="newItem">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">新增</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <!-- list -->
    <b-row v-if="queryData" class="match-height">
      <b-col lg="2" v-for="(item, idx) in queryData.list" :key="idx">
        <b-card
          no-body
          class="lab-item"
          footer-class="lab-footer"
          @click="editItem(item.id)"
        >
          <b-card-header>
            <b-media vertical-align="top">
              <template #aside>
                <b-icon-sort-down-alt scale="2" variant="primary" />
              </template>
              <h4>{{ item.title }}</h4>
            </b-media>
            <!-- <b-card-title>{{item.title.substr(0,8)}}...</b-card-title> -->
          </b-card-header>

          <b-card-body>
            <div>{{ item.overview.substr(0, 30) }}...</div>
          </b-card-body>

          <b-card-text class="text-right mr-1 mb-1">
            发布日期  {{item.published}}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <!-- pagination -->
    <b-row>
      <b-col cols="12" class="mt-1">
        <b-pagination
          v-if="queryData"
          v-model="listTableCurrentPage"
          :total-rows="listTableTotalRows"
          :per-page="listTablePerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>


<script>
import {
  BMedia,
  BMediaAside,
  BIconShieldLock,
  BIconSortDownAlt,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BCardFooter,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
  BFormFile,
  BModal,
} from "bootstrap-vue";

import {
  industryOptions,
  complianceOptions,
  surveyTypeOptions,
} from "../../options";

export default {
  name: "Librarysurvey",

  components: {
    BMedia,
    BMediaAside,
    BIconShieldLock,
    BIconSortDownAlt,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BCardFooter,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    BFormFile,
    BModal,
  },

  data() {
    return {
      queryData: null,

      // importData: null,

      // select options
      industryOptions,
      complianceOptions,


      // query
      query_industry: "",
      query_compliance: "",
      query_type: "",
      query_keyword: "",

      //      
      listTablePerPage: 5,
      listTablePageOptions: [5, 15, 30],
      listTableTotalRows: 1,
      listTableCurrentPage: 1,

      // view
      currentItem: null,
    };
  },

  computed: {
    listTableItems() {
      return this.queryData ? this.queryData.list : [];
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    doSearch() {
      this.$http
        .get("/api/lib-flow", {
          params: {
            q: this.query_keyword,
            industry: this.query_industry,
            compliance: this.query_compliance,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.queryData = res.data;
        });
    },

    //
    editItem(id) {
      this.$router.push("/labrary/flow/" + id + "/edit");
    },

    //
    newItem() {
      //TODO:
    },

    // parse excel json
    parseJson(json){
      let sheet = json['Sheet1']
      this.queryData.list.splice(0)

      for (let i = 2; i < sheet.length; i++) {
        let row = sheet[i]
        if (row.length <11) return;

        let item = {}
        item.id = row[0]
        item.name = row[1]
        item.industry = row[2]
        item.enterpise = row[3]
        item.time = row[4]
        item.description = row[5]
        item.violation = row[6]
        item.basis = row[7]
        item.unit = row[8]
        item.type = row[9]
        item.punishment = row[10]
        console.log(item)

        this.queryData.list.push(item)

      }
    },

    //
    deleteItem(data) {
      let name = data.item.name;
      this.$bvModal
        .msgBoxConfirm(`是否确定删除企业“ ${name}”？`, {
          title: "删除确认",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          console.log(value);
          this.$http.delete(`/api/enterprise/${data.item.id}`).then((res) => {
            this.$bvToast.toast(`${name} 删除成功`, {
              title: `操作成功`,
              variant: "success",
              solid: true,
            });
            this.doSearch();
          });
        });
    },

    onEditHidden() {
      this.showEdit = false;
    },
  },
};
</script>

<style lang="scss" >
.lib-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}
.lib-footer {
  font-size: 1rem !important;
  text-align: right !important;
}

.match-height > [class*="col"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  cursor: pointer;
}

.match-height > [class*="col"] > .card {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
</style>